import React, { Component } from 'react'

function About () {
  return (
    <div>
      <div id='main-header'><h1>About Us</h1></div>
        <div className='main-content'>
          <div className="animate__animated animate__fadeInUp">
            <p>
            Making pierogi is in our blood. From Poland to Cleveland, our family has made pierogi for generations. All our generously-sized pierogi are made from scratch - from our dough to our filling - using fresh, non-GMO, and locally produced ingredients.  Using an eco-friendly and delicious production cycle, our herbs are home grown in our very own garden, and all organic waste is composted and worked backed into the soil. We are passionate and dedicated to delivering homemade, sustainable pierogi to our loyal customers.
            </p>
            <hr/>
          <h2>Respect the Pierogi!</h2>
            <p>
            Pierogi are a staple of Central and Eastern European cuisine. While it’s exact origins remain a matter of debate, this Polish dumpling holds a special place in the hearts of many Americans. In areas with a high volume of Eastern European immigrants, pierogi were an affordable and filling reminder of their rich heritage. Like the people who eat them, pierogi take time and hard work to produce. As such, making pierogi is an important source of communal activity, with families and churches gathering together to pinch, boil, and package. At Plosk Bros, we honor these foundational characteristics by maintaining the hard-working, family-oriented approach to making pierogi. For us, pierogi is a symbol of our Polish heritage and our family’s future as proud Polish-Americans.
            </p>
            <hr/>
          <h3>Preparing Pierogi:</h3>
            <p>
              There are many ways to prepare pierogi, and everyone has their favorite.
            </p>
            <p>
              Our pierogi come cooked and frozen. All you need to do is drop them In boiling water for a few minutes and enjoy!
            </p>
            <p>
              Another popular way to serve pierogi is to, after boiling them, pan fry your pierogi with butter and onion, serve with sour cream, and savor this tried and true method!
            </p>
            <p>
              One of our favorite ways to prepare pierogi is deep frying them. The dough comes out crisp and flavorful and is a great new way to enjoy an old favorite!
            </p>
            <p>
              While pierogi can be served on its own, some popular accompanying dishes include cabbage and noodles, kielbasa, steamed vegetables, bacon and onions, and many others. Pierogi is a versatile dish. Get creative and experiment, because as long as there are pierogi involved, you can’t go wrong!
            </p>
          </div>
        </div>
    </div>
  )
}


export default About;
