import React, { Component } from 'react'

class Footer extends Component {
  render() {
    return (
      <div className="container-fluid" style={footerStyle}>
      <footer>&copy; Copyright 2020 pierogi PLOSK bros LLC</footer>
      </div>
    )
  }
}

const footerStyle ={
  background: '#E5E5E5',
  paddingBottom: '5px',
  textAlign: 'center',
}

export default Footer
