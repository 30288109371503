import React, { Component } from 'react';
import Carousel from '../components/Carousel';

class HomePage extends Component {
  render() {
    return (
        <div className="row" style={homeStyle} >
          <div className="col-md-8">
            <div style={carouselStyle}>
              <Carousel />
            </div>
              <div id='main-header'><h1>Pierogies Delievered Fresh</h1></div>
              <div className='main-content'>
                <h2>Order</h2>
                <p>All our pierogi are sold by the dozen and delivered directly to you. Payment upon delivery!</p>
                <p>For orders of 10 dozen or less: $12/dozen.</p>
                <p>For orders over 10 dozen, please call or email for pricing. We offer competitive pricing!</p>
                <p>Please call to schedule fundraising and distribution orders!</p>
                <p>To order, call ‪(440) 201-9630‬ or fill out the contact form. Give us your name, address, and order and we will deliver your homemade pierogi.</p>
              </div>
          </div>
          <div className="col-md-4" style ={fbStyle}>
            <div className="ratio" id='fbplugin'>
              <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FPlosk-Bros-Pierogi-105270174818916&tabs=timeline&width=340&height=1000&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" title="ploskbros.com Facebook" width="340" height="1000" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
          </div>
        </div>
    );
  }
}

const homeStyle ={
  textAlign: 'left',
}

const carouselStyle ={
  margin: '20px',
}

const fbStyle ={
  paddingTop: '20px',
}

export default HomePage;
