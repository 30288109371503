import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar'
import HomePage from './components/HomePage';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';

import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
            <Header />
            <Navbar />
            <div className="container-fluid" id='container'>
              <Route exact path="/" render={props => (
                <React.Fragment>
                  <HomePage />
                </React.Fragment>
              )} />
              <Route exact path="/about" render={props => (
                <React.Fragment>
                  <About />
                </React.Fragment>
              )} />
              <Route exact path="/contact" render={props => (
                <React.Fragment>
                  <Contact />
                </React.Fragment>
              )} />
            </div>
            <Footer />
        </div>
        </Router>
    );
  }
}

export default App;
