import React from 'react';
import CallBtn from '../components/CallBtn';

function Navbar () {
  return (
    <div className="row" style={navStyle}>
      <div className="col">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link" href="/">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact">Contact/Order</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="col" style={{textAlign: 'right'}}>
        <CallBtn />
      </div>
    </div>
  )
}

const navStyle ={
  background: '#A8AFB9',
  borderTop: '3px solid #FE1800',
  marginRight: '.5px',
}

export default Navbar;
