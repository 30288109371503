import React, { Component } from 'react'

class Contact extends Component {
  render() {
    return (
      <div>
        <div id='main-header'><h1>Contact Us</h1></div>
        <div className="row">
          <div className="col-md-6">
            <div className='main-content'>
              <p>
                If you would like to put in an online order, please make sure to include a valid phone number and an email address.
              </p>
              <div className="animate__animated animate__bounce">
                <h2>Order</h2>
              </div>
              <p>All our pierogi are sold by the dozen and delivered directly to you. Payment upon delivery!
              </p>
              <p>For orders of 10 dozen or less: $12/dozen.
              </p>
              <p>For orders over 10 dozen, please call or email for pricing. We offer competitive pricing!
              </p>
              <p>Please call to schedule fundraising and distribution orders!
              </p>
              <p>To order, call ‪(440) 201-9630‬ or fill out the contact form. Give us your name, address, and order and we will deliver your homemade pierogi.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="ratio ratio-16x9">
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeYwFY2BgrmluKjYt68keqQfd6lGQeynW4-hRkDBnN-5i482g/viewform?embedded=true" height="750" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
          </div>
          </div>
        </div>
    )
  }
}

export default Contact
