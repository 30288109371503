import React from 'react';

function Header() {
  return (
    <header style={headerStyle}>
      <div>
          <img src="/images/Logo.png" alt="..." style={imageStyle} className="animate__animated animate__bounceInDown img-fluid" id='logo' />
      </div>
    </header>
  )
}

const headerStyle = {
  color: '#cc0000',
  textAlign: 'centers',
  padding: '30px',
}

const imageStyle ={
  maxHeight: '300px',
  maxWidth: '400px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  minHeight: '100px',
  minWidth: '250px',
}

export default Header;
