import React, { Component } from 'react';

class Carousel extends Component {
  render() {
    return (
      <div>
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
          <ol className="carousel-indicators">
            <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"></li>
            <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></li>
            <li data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="/images/platedpierogis.jpg" className="d-block w-100" alt="..." style={image} />
              <div className="carousel-caption animate__animated animate__backInLeft fluid" style={text}>
                <h3>Try a bite!</h3>
                <p>Our homemade pierogi will leave you wanting more...</p>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Pierogi_frying.jpg" className="d-block w-100" alt="..." style={image} />
              <div className="carousel-caption animate__animated animate__backInUp" style={text} >
                <h3>Cooked to perfection!</h3>
                <p>Our generously-sized pierogi made perfectly for you.</p>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/dumplingsServed.jpg" className="d-block w-100" alt="..." style={image} />
              <div className="carousel-caption animate__animated animate__backInRight" style={text} >
                <h3>From our home, to yours!</h3>
                <p>Enjoy these delicious, fresh flavors whenever you want.</p>
              </div>
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </a>
        </div>
      </div>
    )
  }
}

const image={
  maxHeight: '450px',
  minHeight: '300px',
}

const text={
  color: '#000',
  background: 'rgba(222, 222, 222, 0.5)',
  fontSize: '20px',
}

export default Carousel;
